















































































































import { TwitchUser } from '@/api/OAuthApi';
import { BesserGuesserClient } from '@/models/BesserGuesserClient';
import { WebsocketClient } from '@/models/WebsocketClient';
import { BesserGuesserLoopJSON, CandidateJSON, LoopState, MultiQuestionAnswerLog, Question, QuestionMatrix, RoundResult, ScoreCorrection, TotalCandidateScore } from '@/types/BesserGuesser';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { toTotalScore } from '@/functions';

@Component
export default class MultiQuestionComponent extends Vue {
  @Prop({ type: Boolean, default: false }) public streamOverlay!: boolean;
  private websocketClient = this.$store.getters.websocketClient as WebsocketClient;
  private besserGuesserClient = this.$store.getters.besserGuesserClient as BesserGuesserClient;
  public guess = '';

  public getGuess (index: number): string {
    return this.ownMultiAnswers.find(a => a.childQuestionIndex === index)?.answer ?? '';
  }

  public getGuessStatus (index: number): MultiQuestionAnswerLog['status'] {
    return this.ownMultiAnswers.find(a => a.childQuestionIndex === index)?.status ?? 'incorrect';
  }

  public getGuessStatusIcon (index: number): string {
    switch (this.getGuessStatus(index)) {
      case 'correct': return 'mdi-check';
      case 'incorrect': return 'mdi-close';
      case 'pending': return 'mdi-timer-sand-empty';
      default: return 'mdi-help';
    }
  }

  public submitMultiGuess (): void {
    this.websocketClient.submitMultiGuess(this.guess);
  }

  public get totalScores (): TotalCandidateScore[] {
    return this.candidates.map(c => ({
      candidate: c,
      score: toTotalScore(c.id, this.roundResults, this.scoreCorrections, [])
    })).sort((a, b) => b.score - a.score);
  }

  public get scoreCorrections (): ScoreCorrection[] {
    return this.besserGuesserLoopJSON?.scoreCorrection ?? [];
  }

  public get roundResults (): RoundResult[] {
    return this.besserGuesserLoopJSON?.roundResults ?? [];
  }

  public get correctlyGuessedIndex (): number | null {
    const index = this.ownMultiAnswers.findIndex(a => a.status === 'correct') ?? -1;
    return index === -1 ? null : index;
  }

  public get guessedCorrectly (): boolean {
    return this.ownMultiAnswers.some(a => a.status === 'correct') ?? false;
  }

  public get roundTime (): number | null {
    return this.besserGuesserLoopJSON?.roundTime ?? null;
  }

  public get showRoundTimer (): boolean {
    return this.loopState === 'waitingForMultiQuestionChildAnswer';
  }

  public get roundTimePercentage (): number {
    if (!this.roundTime || !this.besserGuesserLoopJSON) return 100;
    return (this.roundTime - 1) / (this.besserGuesserLoopJSON.roundDuration - 1) * 100;
  }

  public get ownMultiAnswers (): MultiQuestionAnswerLog[] {
    return this.besserGuesserLoopJSON?.multiAnswers.filter(a => a.candidateId === this.user?.id) ?? [];
  }

  public get questionMatrix (): QuestionMatrix | null {
    return this.besserGuesserClient.getQuestionMatrix();
  }

  public get besserGuesserLoopJSON (): BesserGuesserLoopJSON | null {
    return this.besserGuesserClient.getLoop();
  }

  public get activeQuestionIndex (): [number, number] | null {
    return this.besserGuesserLoopJSON?.activeQuestionIndex ?? null;
  }

  public get loopState (): LoopState {
    return this.besserGuesserLoopJSON?.state ?? 'unknown';
  }

  public get activeChildQuestionIndex (): number {
    return this.besserGuesserLoopJSON?.currentChildIndex ?? -1;
  }

  public get currentQuestion (): Question | null {
    if (!this.questionMatrix || !this.activeQuestionIndex) return null;
    return this.questionMatrix[this.activeQuestionIndex[0]].questions[this.activeQuestionIndex[1]];
  }

  public get candidates (): CandidateJSON[] {
    return this.besserGuesserClient.getCandidates();
  }

  public get user (): TwitchUser | null {
    return this.$store.getters.user;
  }

  @Watch('loopState')
  public handleLoopStateChange (): void {
    // Reset input field upon new child round
    if (this.loopState === 'waitingForMultiQuestionChildAnswer') this.guess = '';
  }
}
