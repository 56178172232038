


























import { CandidateJSON, GuessRoundResult, RoundAnswer } from '@/types/BesserGuesser';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AnswerList extends Vue {
  @Prop({ type: Array, required: true }) public candidates!: CandidateJSON[];
  @Prop({ type: Object, required: true }) private roundResult!: GuessRoundResult;
  @Prop({ type: Boolean, default: false }) public showWinner!: boolean;

  private getAnswer (candidateId: string): number | null {
    return this.roundResult.answers.find(a => a.candidateId === candidateId)?.answer ?? null;
  }

  public isBestAnswer (candidateId: string): boolean {
    return this.roundResult.bestAnswer?.candidateId === candidateId;
  }

  public isWorstAnswer (candidateId: string): boolean {
    return this.roundResult.worstAnswer?.candidateId === candidateId;
  }

  public getDiffString (candidateId: string): string | null {
    if (this.roundResult.question.key !== 'guess') return null;
    const answer = this.getAnswer(candidateId);
    const solution = this.roundResult.question.answer;

    if (answer === null) return null;
    const diff = (Math.abs(solution - answer));
    return diff.toString();
  }

  public getAnswerString (candidateId: string): string {
    const answer = this.getAnswer(candidateId);
    return answer === null ? '-' : answer.toString();
  }

  private get maxAnswerValue (): number {
    const answers = this.roundResult.answers.map(a => a.answer);
    if (this.chatAnswer) answers.push(this.chatAnswer.answer);
    return Math.max(...answers);
  }

  public get chatAnswer (): RoundAnswer | null {
    return this.roundResult.chatAnswer;
  }

  public get chatDiff (): string | null {
    if (this.roundResult.question.key !== 'guess') return null;
    const answer = this.roundResult.chatAnswer?.answer;
    const solution = this.roundResult.question.answer;

    if (answer === undefined) return null;
    const diff = (Math.abs(solution - answer));
    return diff.toString();
  }

  public get solutionstring (): string {
    if (!this.showWinner) return '...';
    return this.roundResult.question.answer.toString();
  }

  public get unitString (): string {
    if (this.roundResult.question.key !== 'guess' || !this.showWinner) return '';
    return this.roundResult.question.unit ?? '';
  }
}
