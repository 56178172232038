



















import { OAuthApi, TwitchUser } from '@/api/OAuthApi';
import { BesserGuesserClient } from '@/models/BesserGuesserClient';
import { WebsocketClient } from '@/models/WebsocketClient';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HomeView extends Vue {
  private websocketClient = this.$store.getters.websocketClient as WebsocketClient;
  private besserGuesserClient = this.$store.getters.besserGuesserClient as BesserGuesserClient;

  private connect (): void {
    this.websocketClient.connect();
  }

  public toCandidateView (): void {
    if (!this.user) return;
    this.$router.push({ name: 'candidate', params: { t: this.user.id } });
  }

  public toAdminView (): void {
    if (!this.user) return;
    this.$router.push({ name: 'admin' });
  }

  public login (): void {
    OAuthApi.connect();
  }

  public get user (): TwitchUser | null {
    return this.$store.getters.user;
  }
}
