export enum AudioState {
  ON = 'on',
  OFF = 'off'
}

export enum SoundEffectKey {
  SUCCESS = 'success',
  TICKER = 'ticker',
  SWOOSH = 'swoosh',
  JOKER = 'joker',
  BUZZER = 'buzzer',
  WRONG = 'wrong'
}

type SoundEffects = {
  success: HTMLAudioElement;
  ticker: HTMLAudioElement;
  swoosh: HTMLAudioElement;
  joker: HTMLAudioElement;
  wrong: HTMLAudioElement;
  buzzer: [HTMLAudioElement, HTMLAudioElement, HTMLAudioElement, HTMLAudioElement];
}

export class SoundManager {
  public static instance: SoundManager | undefined;
  private audioState = AudioState.ON;
  private volume = 1;

  private soundEffects: SoundEffects = {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    success: new Audio(require('./../assets/success-full.ogg')),
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    ticker: new Audio(require('./../assets/ticker.ogg')),
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    swoosh: new Audio(require('./../assets/swoosh.ogg')),
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    joker: new Audio(require('./../assets/joker.ogg')),
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    wrong: new Audio(require('./../assets/wrong.ogg')),
    buzzer: [
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      new Audio(require('./../assets/buzzer-cat.ogg')),
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      new Audio(require('./../assets/buzzer-cat.ogg')),
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      new Audio(require('./../assets/buzzer-cat.ogg')),
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      new Audio(require('./../assets/buzzer-cat.ogg'))
    ]
  };

  public static getInstance (): SoundManager {
    if (!SoundManager.instance) {
      SoundManager.instance = new SoundManager();
    }
    return SoundManager.instance;
  }

  public setVolume (vol: number): void {
    this.volume = vol;
    this.updateVolumeOfSounds();
  }

  public getVolume (): number {
    return this.volume;
  }

  private updateVolumeOfSounds (): void {
    const totalVolume = this.volume;

    (Object.keys(this.soundEffects) as Array<keyof SoundEffects>).forEach(key => {
      const e = this.soundEffects[key];
      if (Array.isArray(e)) {
        e.forEach(s => {
          s.volume = totalVolume;
        });
      } else {
        e.volume = totalVolume;
      }
    });
  }

  public toggleState (): void {
    if (this.audioState === AudioState.ON) {
      this.audioState = AudioState.OFF;
    } else {
      this.audioState = AudioState.ON;
    }
    localStorage.setItem('audio', this.audioState);
  }

  public play (key: SoundEffectKey, index?: number): void {
    const e = this.soundEffects[key];
    if (!e) return;

    if (Array.isArray(e)) {
      e[index ?? 0].play();
    } else {
      e.play();
    }
  }

  public stop (key: SoundEffectKey, index?: number): void {
    const e = this.soundEffects[key];
    if (!e) return;

    if (Array.isArray(e)) {
      e[index ?? 0].pause();
      e[index ?? 0].currentTime = 0;
    } else {
      e.pause();
      e.currentTime = 0;
    }
  }
}
