





import { EvaluatedMultiAnswer } from '@/types/BesserGuesser';
import { Vue, Component, Ref } from 'vue-property-decorator';
import md5 from 'md5';
import gsap from 'gsap';

type VisualizedItem = {
  id: string;
  element: HTMLElement;
}

@Component
export default class MultiAnswerEvaluationVisualizer extends Vue {
  @Ref('main') private main!: HTMLElement;
  public items: VisualizedItem[] = [];

  public trigger (event: EvaluatedMultiAnswer): void {
    const id = `_${md5((Date.now() * Math.random()).toString())}`;
    const element = document.createElement('div');
    element.id = id;
    element.classList.add('multi-answer', event.status);

    const nameChild = document.createElement('div');
    nameChild.classList.add('name', 'righteous');
    nameChild.innerText = event.name;

    const avatarChild = document.createElement('img');
    avatarChild.src = event.avatar;
    avatarChild.classList.add('avatar');

    element.appendChild(avatarChild);
    element.appendChild(nameChild);

    this.main.appendChild(element);
    this.items.push({ id, element });

    const tl = gsap.timeline({ repeat: 2, repeatDelay: 1 });
    tl.from(`#${id}`, { opacity: 0, scale: 0.3, duration: 0.5, ease: 'elastic.out' });
    tl.to(`#${id}`, { y: -200, duration: 3, ease: 'power1.in', delay: -0.5 });
    tl.to(`#${id}`, { opacity: 0, duration: 2, delay: -2, ease: 'power1.in' });
    tl.to(`#${id}`, { scale: 0.8, duration: 2, delay: -2, ease: 'power1.in' });

    setTimeout(() => {
      const index = this.items.findIndex(e => e.id === id);
      if (index > -1) {
        this.items.splice(index, 1);
      }
      element.remove();
    }, 3000);
  }
}
