























import { QuestionCategoryContainer, QuestionMatrix } from '@/types/BesserGuesser';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class QuestionTable extends Vue {
  @Prop({ type: Array, required: true }) public questionMatrix!: QuestionMatrix;
  @Prop({ type: Array, required: true }) private usedQuestionIndices!: [number, number][];
  @Prop({ type: Array, default: null }) private activeQuestionIndex!: [number, number] | null;
  @Prop({ type: Boolean, default: true }) private readonly!: boolean;
  @Prop({ type: Boolean, default: false }) public multiLanguage!: boolean;

  public getCategoryColumnStyle (cat: QuestionCategoryContainer): string {
    return `background-color: ${cat.color}`;
  }

  public getCategoryBoxBackgroundEffectStyle (color: string): string {
    return `background-color: ${color}; filter: brightness(85%)`;
  }

  public getQuestionBoxBackgroundEffectStyle (color: string, index: number): string {
    return `background-color: ${color}; filter: brightness(${100 - (index * 10)}%)`;
  }

  public questionIsUsed (index: [number, number]): boolean {
    return this.usedQuestionIndices.filter(q => q[0] === index[0] && q[1] === index[1]).length > 0;
  }

  public questionIsActive (index: [number, number]): boolean {
    if (!this.activeQuestionIndex) return false;
    return (this.activeQuestionIndex[0] === index[0] && this.activeQuestionIndex[1] === index[1]);
  }

  public handleQuestionClick (column: number, row: number): void {
    if (this.readonly || !this.questionMatrix || this.questionIsUsed([column, row])) return;
    // const cat = this.questionMatrix[column];
    // if (!cat) return;
    // const question = cat.questions[row];
    // if (!question) return;
    this.$emit('selectQuestionIndex', [column, row]);
  }
}
