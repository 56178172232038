import { BuzzRoundResult, ChatTotalScore, GuessRoundResult, MultiRoundResult, RoundResult, ScoreCorrection, UsedJoker } from '@/types/BesserGuesser';

export function toClipboard (s: string): void {
  const el = document.createElement('textarea');
  el.value = s;
  el.setAttribute('readonly', '');
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export function toChatTotalScores (roundResults: RoundResult[]): ChatTotalScore[] {
  const totals: ChatTotalScore[] = [];
  roundResults.filter(isGuessRound).forEach(roundResult => {
    roundResult.chatResults.forEach(c => {
      if (totals.findIndex(t => t.name === c.name) === -1) totals.push({ name: c.name, score: 0 });
      const found = totals.find(t => t.name === c.name);
      if (found) {
        found.score += c.points;
      }
    });
  });
  return totals.sort((a, b) => b.score - a.score);
}

export function usedJoker (round: number, candidateId: string, usedJokers: UsedJoker[]): boolean {
  return usedJokers.findIndex(u => u.round === round && u.candidateId === candidateId) > -1;
}

function isGuessRound (roundResult: RoundResult): roundResult is GuessRoundResult {
  return roundResult.questionType === 'guess';
}

function isBuzzRound (roundResult: RoundResult): roundResult is BuzzRoundResult {
  return roundResult.questionType === 'buzz';
}

function isMultiRound (roundResult: RoundResult): roundResult is MultiRoundResult {
  return roundResult.questionType === 'multi';
}

export function toTotalScore (candidateId: string, roundResults: RoundResult[], scoreCorrections: ScoreCorrection[], usedJokers: UsedJoker[]): number {
  const allBuzzRounds = roundResults.filter(isBuzzRound);
  const allGuessRounds = roundResults.filter(isGuessRound);
  const allMultiRounds = roundResults.filter(isMultiRound);

  // First all scores for guess questions
  const positiveGuessRoundScore = allGuessRounds
    .filter(r => r.bestAnswer?.candidateId === candidateId)
    .reduce(function (carry, r) {
      const usedJokerInRound = usedJoker(r.round, candidateId, usedJokers);
      return carry + (r.question.points * (usedJokerInRound ? 2 : 1));
    }, 0);

  const negativeGuessRoundScore = allGuessRounds
    .filter(r => r.worstAnswer?.candidateId === candidateId)
    .reduce(function (carry, r) {
      const usedJokerInRound = usedJoker(r.round, candidateId, usedJokers);
      return carry - (r.question.points * (usedJokerInRound ? 2 : 1));
    }, 0);

  // Then all scores for buzz questions
  const positiveBuzzRoundScore = allBuzzRounds
    .filter(r => r.correctlyAnsweredBy === candidateId)
    .reduce(function (carry, r) {
      const usedJokerInRound = usedJoker(r.round, candidateId, usedJokers);
      return carry + (r.question.points * (usedJokerInRound ? 2 : 1));
    }, 0);

  const negativeBuzzRoundScore = allBuzzRounds
    .filter(r => r.incorrectlyAnsweredBy.includes(candidateId))
    .reduce(function (carry, r) {
      const usedJokerInRound = usedJoker(r.round, candidateId, usedJokers);
      return carry - (r.question.points * (usedJokerInRound ? 2 : 1));
    }, 0);

  // Then all scores for multi questions
  const positiveMultiRoundScore = allMultiRounds
    .filter(r => r.correctlyAnsweredBy?.find(a => a.candidateId === candidateId))
    .reduce(function (carry, r) {
      // const usedJokerInRound = usedJoker(r.round, candidateId, usedJokers);
      return carry + (r.question.children[r.correctlyAnsweredBy?.find(a => a.candidateId === candidateId)?.childQuestionIndex ?? -1]?.points ?? 0);
    }, 0);

  const correctedScore = scoreCorrections
    .filter(r => r.candidateId === candidateId)
    .reduce(function (carry, r) {
      return carry + r.points;
    }, 0);

  return (
    positiveGuessRoundScore +
    negativeGuessRoundScore +
    positiveBuzzRoundScore +
    negativeBuzzRoundScore +
    positiveMultiRoundScore +
    correctedScore);
}
