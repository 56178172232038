









import { BesserGuesserClient, BesserGuesserEventListener } from '@/models/BesserGuesserClient';
import { WebsocketClient } from '@/models/WebsocketClient';
import { BesserGuesserJSON, BesserGuesserLoopJSON, CandidateJSON, LoopState, MultiQuestionAnswerLog, Question, QuestionMatrix, RoundResult, ScoreCorrection, TotalScore } from '@/types/BesserGuesser';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { toTotalScore } from '@/functions';
import MultiQuestionComponent from '@/components/MultiQuestionComponent.vue';
import MultiAnswerEvaluationVisualizer from '@/components/MultiAnswerEvaluationVisualizer.vue';

@Component({
  components: {
    MultiQuestionComponent,
    MultiAnswerEvaluationVisualizer
  }
})
export default class StreamOverlayViewV2 extends Vue {
  @Ref('multiAnswerEvaluationVisualizer') public multiAnswerEvaluationVisualizer!: MultiAnswerEvaluationVisualizer;
  private websocketClient = this.$store.getters.websocketClient as WebsocketClient;
  private besserGuesserClient = this.$store.getters.besserGuesserClient as BesserGuesserClient;
  public totalScores: TotalScore[] = [];
  private listeners: BesserGuesserEventListener[] = [];

  private mounted (): void {
    this.connect();
    this.totalScores = [...this.currentTotalScores];

    this.listeners.push(this.besserGuesserClient.on('multiAnswerUpdate', (data: MultiQuestionAnswerLog) => {
      const candidate = this.candidates.find(c => c.id === data.candidateId);
      if (!candidate) return;

      this.multiAnswerEvaluationVisualizer.trigger({
        name: candidate.name,
        avatar: candidate.avatar,
        status: data.status
      });
    }));
  }

  public testTrigger (): void {
    this.multiAnswerEvaluationVisualizer.trigger({
      name: 'Test',
      avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/14df214f-79e0-4b9f-b973-7b80c30f76bd-profile_image-70x70.png',
      status: 'incorrect'
    });
  }

  private connect (): void {
    this.websocketClient.connect();
  }

  private destroyed (): void {
    this.listeners.forEach(l => {
      this.besserGuesserClient.removeListener(l);
    });
  }

  public get currentTotalScores (): TotalScore[] {
    return this.candidates.map(c => ({
      candidateId: c.id,
      score: toTotalScore(c.id, this.roundResults, this.scoreCorrections, [])
    }));
  }

  private get connectionId (): string | null {
    return this.websocketClient.getConnectionId();
  }

  private get besserGuesserJSON (): BesserGuesserJSON | null {
    return this.besserGuesserClient.getJSON();
  }

  private get besserGuesserLoopJSON (): BesserGuesserLoopJSON | null {
    return this.besserGuesserClient.getLoop();
  }

  public get roundTime (): number {
    return this.besserGuesserLoopJSON?.roundTime ?? 0;
  }

  public get roundTimePercentage (): number {
    if (!this.roundTime || !this.besserGuesserLoopJSON) return 100;
    return (this.roundTime - 1) / (this.besserGuesserLoopJSON.roundDuration - 1) * 100;
  }

  private get showRoundTimer (): boolean {
    return this.besserGuesserLoopJSON?.state === 'waitingForAnswers';
  }

  public get showCurrentQuestion (): boolean {
    return this.besserGuesserLoopJSON?.state === 'waitingForAnswers' || this.besserGuesserLoopJSON?.state === 'waitingForBuzz';
  }

  public get showAnswers (): boolean {
    return (
      this.loopState === 'waitingForAnswersBeingShown' ||
      this.showRoundAnswers ||
      this.showRoundWinner
    );
  }

  public get loopHasEnded (): boolean {
    return this.loopState === 'ended';
  }

  public get questionMatrix (): QuestionMatrix | null {
    return this.besserGuesserClient.getQuestionMatrix();
  }

  public get activeQuestionIndex (): [number, number] | null {
    return this.besserGuesserLoopJSON?.activeQuestionIndex ?? null;
  }

  public get currentRound (): number {
    return this.besserGuesserLoopJSON?.round ?? 0;
  }

  public get currentRoundResult (): RoundResult | null {
    const currentRound = this.besserGuesserLoopJSON?.round;
    if (!currentRound) return null;
    return this.besserGuesserLoopJSON?.roundResults.find(r => r.round === currentRound) ?? null;
  }

  public get questionVisible (): boolean {
    return this.besserGuesserLoopJSON?.questionVisible ?? false;
  }

  public get currentQuestion (): Question | null {
    if (!this.questionMatrix || !this.activeQuestionIndex) return null;
    return this.questionMatrix[this.activeQuestionIndex[0]].questions[this.activeQuestionIndex[1]];
  }

  public get usedQuestionIndices (): [number, number][] {
    return this.besserGuesserClient.getUsedQuestionIndices();
  }

  public get loopState (): LoopState | null {
    return this.besserGuesserLoopJSON?.state ?? null;
  }

  public get showRoundAnswers (): boolean {
    return this.loopState === 'waitingForResultsBeingShown';
  }

  public get showRoundWinner (): boolean {
    return this.loopState === 'resultsShown';
  }

  @Watch('besserGuesserLoopJSON')
  private handleLoopUpdate (): void {
    if (this.loopState !== 'waitingForAnswersBeingShown' && this.loopState !== 'waitingForResultsBeingShown') {
      this.totalScores = [...this.currentTotalScores];
    }
  }

  // @Watch('loopState')
  // private handleLoopStateChange (): void {
  //   if (this.loopState === 'open') {
  //     ConfettiEmitter.clearIntervals();
  //   } else if (this.loopState === 'waitingForAnswers') {
  //     SoundManager.getInstance().stop(SoundEffectKey.TICKER);
  //     SoundManager.getInstance().play(SoundEffectKey.TICKER);
  //   } else if (this.loopState === 'resultsShown') {
  //     if (this.currentRoundResult?.questionType === 'guess') {
  //       const bestAnswerCandidateId = this.currentRoundResult?.bestAnswer?.candidateId;
  //       if (bestAnswerCandidateId) {
  //         const candidateIndex = this.candidates.findIndex(c => c.id === bestAnswerCandidateId);
  //         if (candidateIndex > -1) {
  //           ConfettiEmitter.success(this.getColor(candidateIndex), this.getXPos(candidateIndex), this.getYPos(candidateIndex), this.getAngle(candidateIndex));
  //           SoundManager.getInstance().play(SoundEffectKey.SUCCESS);
  //         }
  //       }
  //     }
  //   } else if (this.loopState === 'ended') {
  //     ConfettiEmitter.fireworks();
  //   }
  // }

  public get candidates (): CandidateJSON[] {
    return this.besserGuesserJSON?.candidates ?? [];
  }

  public get roundResults (): RoundResult[] {
    return this.besserGuesserLoopJSON?.roundResults ?? [];
  }

  public get scoreCorrections (): ScoreCorrection[] {
    return this.besserGuesserLoopJSON?.scoreCorrection ?? [];
  }

  @Watch('connectionId')
  private handleChangedConnectionId (): void {
    if (this.connectionId !== null) {
      this.websocketClient.register('spectator')
        .then(r => {
          if (r.status === 'success' && r.data) {
            this.$toast.success('Successfully logged in as spectator');
            this.besserGuesserClient.setToken('');
            this.besserGuesserClient.updateJSON(r.data);
          } else {
            throw new Error(r.message);
          }
        })
        .catch(e => {
          if (e instanceof Error) {
            this.$toast.error(e.message);
          }
        });
    }
  }
}
