






































































































import { BesserGuesserClient } from '@/models/BesserGuesserClient';
import { ReadyState, WebsocketClient } from '@/models/WebsocketClient';
import { Component, Vue, Watch } from 'vue-property-decorator';
import CandidateForm from '@/components/CandidateForm/CandidateForm.vue';
import QuestionTable from '@/components/QuestionTable/QuestionTable.vue';
import CandidateList from '@/components/CandidateList.vue';
import ChatHighscoreList from '@/components/ChatHighscoreList.vue';
import AnswerList from '@/components/AnswerList.vue';
import ConnectionComponent from '@/components/ConnectionComponent.vue';
import { BesserGuesserJSON, BesserGuesserLoopJSON, CandidateJSON, ChatAnswer, LoopState, MultiQuestionAnswerLog, Question, QuestionMatrix, RoundResult, ScoreCorrection } from '@/types/BesserGuesser';
import { SoundEffectKey, SoundManager } from '@/models/SoundManager';
import { TwitchUser } from '@/api/OAuthApi';

@Component({
  components: {
    CandidateForm,
    QuestionTable,
    CandidateList,
    ChatHighscoreList,
    AnswerList,
    ConnectionComponent
  }
})
export default class AdminView extends Vue {
  private websocketClient = this.$store.getters.websocketClient as WebsocketClient;
  private besserGuesserClient = this.$store.getters.besserGuesserClient as BesserGuesserClient;
  private selectedQuestionIndex: [number, number] | null = null;

  public mounted (): void {
    if (!this.user) return;
    this.connect();
  }

  private connect (): void {
    this.websocketClient.connect();
  }

  public toggleChatHighscores (): void {
    this.websocketClient.toggleChatHighscore();
  }

  public evaluateMultiGuess (candidateId: string, correct: boolean): void {
    this.websocketClient.evaluateMultiGuess(candidateId, correct);
  }

  private get connectionId (): string | null {
    return this.websocketClient.getConnectionId();
  }

  public get isConnected (): boolean {
    return this.websocketClient.getReadyState() === ReadyState.OPEN;
  }

  private get besserGuesserJSON (): BesserGuesserJSON | null {
    return this.besserGuesserClient.getJSON();
  }

  private get besserGuesserLoopJSON (): BesserGuesserLoopJSON | null {
    return this.besserGuesserClient.getLoop();
  }

  public get questionMatrix (): QuestionMatrix | null {
    return this.besserGuesserClient.getQuestionMatrix();
  }

  public get usedQuestionIndices (): [number, number][] {
    return this.besserGuesserClient.getUsedQuestionIndices();
  }

  public get activeQuestionIndex (): [number, number] | null {
    return this.besserGuesserLoopJSON?.activeQuestionIndex ?? null;
  }

  public get candidates (): CandidateJSON[] {
    return this.besserGuesserJSON?.candidates ?? [];
  }

  public get loopState (): LoopState | null {
    return this.besserGuesserLoopJSON?.state ?? null;
  }

  public get showRoundWinner (): boolean {
    return this.loopState === 'resultsShown';
  }

  public get roundResults (): RoundResult[] {
    return this.besserGuesserLoopJSON?.roundResults ?? [];
  }

  public get scoreCorrections (): ScoreCorrection[] {
    return this.besserGuesserLoopJSON?.scoreCorrection ?? [];
  }

  public get activeChildQuestionIndex (): number {
    return this.besserGuesserLoopJSON?.currentChildIndex ?? -1;
  }

  public get pendingMultiAnswers (): MultiQuestionAnswerLog[] {
    return this.besserGuesserLoopJSON?.multiAnswers.filter(a => a.status === 'pending' && a.childQuestionIndex === this.activeChildQuestionIndex) ?? [];
  }

  public get currentRoundResult (): RoundResult | null {
    const currentRound = this.besserGuesserLoopJSON?.round;
    if (!currentRound) return null;
    return this.besserGuesserLoopJSON?.roundResults.find(r => r.round === currentRound) ?? null;
  }

  public get chatAnswers (): ChatAnswer[] {
    return this.besserGuesserClient.getChatAnswers();
  }

  public get user (): TwitchUser | null {
    return this.$store.getters.user;
  }

  @Watch('loopState')
  private handleLoopStateChange (): void {
    if (this.loopState && ['waitingForAnswersBeingShown', 'waitingForResultsBeingShown', 'resultsShown'].includes(this.loopState)) {
      SoundManager.getInstance().play(SoundEffectKey.SWOOSH);
    }

    if (this.loopState === 'waitingForQuestion') {
      this.besserGuesserClient.clearChatAnswers();
    }
  }

  // private get token (): string {
  //   return this.$store.getters.token;
  // }

  public pickSelectedQuestion (): void {
    if (!this.selectedQuestionIndex || !this.questionMatrix) return;
    this.websocketClient.selectQuestion(this.selectedQuestionIndex);
  }

  public get canSelectQuestion (): boolean {
    return this.besserGuesserLoopJSON?.state === 'waitingForQuestion';
  }

  public handleSelectedQuestionIndex (index: [number, number]) {
    this.selectedQuestionIndex = index;
  }

  public getCandidate (candidateId: string): CandidateJSON {
    return this.candidates.find(c => c.id === candidateId) ?? { avatar: '', id: candidateId, name: 'unknown', state: 'unknown', isChat: false };
  }

  public get activeCandidateId (): string | null {
    return this.besserGuesserLoopJSON?.activeCandidate?.id ?? null;
  }

  public get selectedQuestion (): Question | null {
    if (!this.questionMatrix) return null;

    const activeQuestionIndex = this.besserGuesserLoopJSON?.activeQuestionIndex;
    if (activeQuestionIndex) {
      return this.questionMatrix[activeQuestionIndex[0]].questions[activeQuestionIndex[1]] ?? null;
    }

    if (!this.selectedQuestionIndex) return null;
    return this.questionMatrix[this.selectedQuestionIndex[0]].questions[this.selectedQuestionIndex[1]];
  }

  @Watch('connectionId')
  private handleChangedConnectionId (): void {
    if (!this.user) return;
    if (this.connectionId !== null) {
      this.websocketClient.register('admin', this.user)
        .then(r => {
          if (r.status === 'success' && r.data) {
            this.$toast.success('Successfully logged in as admin');
            // this.besserGuesserClient.setToken(this.token);
            this.besserGuesserClient.updateJSON(r.data);
          } else {
            throw new Error(r.message);
          }
        })
        .catch(e => {
          if (e instanceof Error) {
            this.$toast.error(e.message);
          }
        });
    }
  }
}
