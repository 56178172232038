




















import { OAuthApi, TwitchUser } from '@/api/OAuthApi';
import { WebsocketClient, ReadyState } from '@/models/WebsocketClient';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ConnectionComponent extends Vue {
  private websocketClient = this.$store.getters.websocketClient as WebsocketClient;
  public ReadyState = ReadyState;

  public login (): void {
    OAuthApi.connect();
  }

  public get connectionStatusString (): string {
    return this.websocketClient.getConnectionStatusString();
  }

  public get connectionId (): string | null {
    return this.websocketClient.getConnectionId();
  }

  public get isConnected (): boolean {
    return this.websocketClient.isConnected();
  }

  public get readyState (): ReadyState {
    return this.websocketClient.getReadyState();
  }

  private connect (): void {
    this.websocketClient.connect();
  }

  public disconnect (): void {
    this.websocketClient.disconnect();
  }

  public get user (): TwitchUser | null {
    return this.$store.getters.user;
  }
}
