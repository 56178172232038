import { render, staticRenderFns } from "./StreamOverlayViewV2.vue?vue&type=template&id=72a0d1f7&"
import script from "./StreamOverlayViewV2.vue?vue&type=script&lang=ts&"
export * from "./StreamOverlayViewV2.vue?vue&type=script&lang=ts&"
import style0 from "./StreamOverlayViewV2.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})
