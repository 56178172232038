



















import { toTotalScore } from '@/functions';
import { BesserGuesserClient } from '@/models/BesserGuesserClient';
import { BesserGuesserLoopJSON, CandidateJSON, LoopState, RoundResult, ScoreCorrection, TotalScore, UsedJoker } from '@/types/BesserGuesser';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class CandidateList extends Vue {
  @Prop({ type: Array, required: true }) public candidates!: CandidateJSON[];
  @Prop({ type: Array, required: true }) private allRoundResults!: RoundResult[];
  @Prop({ type: Array, default: () => [] }) private scoreCorrections!: ScoreCorrection[];
  @Prop({ type: String, default: null }) private activeCandidateId!: string | null;
  private totalScores: TotalScore[] = [];
  private besserGuesserClient = this.$store.getters.besserGuesserClient as BesserGuesserClient;

  private mounted (): void {
    this.totalScores = [...this.currentTotalScores];
  }

  private get currentTotalScores (): TotalScore[] {
    return this.candidates.map(c => ({
      candidateId: c.id,
      score: toTotalScore(c.id, this.allRoundResults, this.scoreCorrections, this.usedJokers)
    }));
  }

  public getTotalScoreByCandidateId (candidateId: string): number {
    const found = this.totalScores.find(s => s.candidateId === candidateId);
    return found ? found.score : 0;
  }

  public candidateIsUsingJoker (candidateId: string): boolean {
    if (!this.besserGuesserLoopJSON) return false;
    return this.besserGuesserLoopJSON.usedJokers.findIndex(u => u.candidateId === candidateId && u.round === this.currentRound) > -1;
  }

  public candidateIdIsActive (candidateId: string): boolean {
    if (!this.activeCandidateId) return false;
    return this.activeCandidateId === candidateId;
  }

  public candidateIdIsSelf (candidateId: string): boolean {
    return this.$store.getters.token === candidateId;
  }

  private get usedJokers (): UsedJoker[] {
    return this.besserGuesserLoopJSON ? this.besserGuesserLoopJSON.usedJokers : [];
  }

  private get besserGuesserLoopJSON (): BesserGuesserLoopJSON | null {
    return this.besserGuesserClient.getLoop();
  }

  private get loopState (): LoopState | null {
    return this.besserGuesserLoopJSON?.state ?? null;
  }

  private get currentRound (): number {
    return this.besserGuesserLoopJSON?.round ?? 0;
  }

  @Watch('besserGuesserLoopJSON')
  private handleLoopUpdate (): void {
    if (this.loopState !== 'waitingForAnswersBeingShown' && this.loopState !== 'waitingForResultsBeingShown') {
      this.totalScores = [...this.currentTotalScores];
    }
  }
}
