




















import { BesserGuesserClient } from '@/models/BesserGuesserClient';
import { ReadyState, WebsocketClient } from '@/models/WebsocketClient';
import { BesserGuesserJSON, BesserGuesserLoopJSON, CandidateJSON, LoopState, RoundResult, ScoreCorrection } from '@/types/BesserGuesser';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TwitchUser } from '@/api/OAuthApi';
import { toTotalScore } from '@/functions';
import MultiQuestionComponent from '@/components/MultiQuestionComponent.vue';

@Component({
  components: {
    MultiQuestionComponent
  }
})
export default class CandidateViewV2 extends Vue {
  private websocketClient = this.$store.getters.websocketClient as WebsocketClient;
  private besserGuesserClient = this.$store.getters.besserGuesserClient as BesserGuesserClient;
  public guess = '';

  private mounted (): void {
    this.connect();
  }

  private connect (): void {
    this.websocketClient.connect();
  }

  private get connectionId (): string | null {
    return this.websocketClient.getConnectionId();
  }

  public get isConnected (): boolean {
    return this.websocketClient.getReadyState() === ReadyState.OPEN;
  }

  public get currentRound (): number {
    return this.besserGuesserLoopJSON?.round ?? 0;
  }

  private get besserGuesserJSON (): BesserGuesserJSON | null {
    return this.besserGuesserClient.getJSON();
  }

  public get besserGuesserLoopJSON (): BesserGuesserLoopJSON | null {
    return this.besserGuesserClient.getLoop();
  }

  public get loopState (): LoopState {
    return this.besserGuesserLoopJSON?.state ?? 'unknown';
  }

  public get candidates (): CandidateJSON[] {
    return this.besserGuesserJSON?.candidates ?? [];
  }

  public get roundResults (): RoundResult[] {
    return this.besserGuesserLoopJSON?.roundResults ?? [];
  }

  public get scoreCorrections (): ScoreCorrection[] {
    return this.besserGuesserLoopJSON?.scoreCorrection ?? [];
  }

  public get ownScore (): number {
    if (!this.user) return -2;
    const user = this.user;
    const self = this.candidates.find(c => c.id === user.id);
    if (!self) return -1;
    return toTotalScore(self.id, this.roundResults, this.scoreCorrections, []);
  }

  public get user (): TwitchUser | null {
    return this.$store.getters.user;
  }

  // public get user (): string {
  //   return this.$store.getters.token;
  // }

  @Watch('connectionId')
  private handleChangedConnectionId (): void {
    if (!this.user) return;
    if (this.connectionId !== null) {
      this.websocketClient.register('candidate', this.user)
        .then(r => {
          if (r.status === 'success' && r.data) {
            this.$toast.success('Successfully logged in as candidate');
            // this.besserGuesserClient.setToken(this.token);
            this.besserGuesserClient.updateJSON(r.data);
          } else {
            throw new Error(r.message);
          }
        })
        .catch(e => {
          if (e instanceof Error) {
            this.$toast.error(e.message);
          }
        });
    }
  }
}
