






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { toClipboard } from '@/functions';
import { CandidateJSON, LoopState, ScoreCorrection } from '@/types/BesserGuesser';
import { BesserGuesserClient } from '@/models/BesserGuesserClient';

@Component
export default class ConnectionComponent extends Vue {
  @Prop({ type: Object, required: true }) public candidate!: CandidateJSON;
  public usernameModel = '';
  public scoreCorrection = 0;
  private besserGuesserClient = this.$store.getters.besserGuesserClient as BesserGuesserClient;

  private mounted (): void {
    this.usernameModel = this.candidate.name;
  }

  public remove (): void {
    this.$emit('remove', this.candidate);
  }

  public updateName (): void {
    this.candidate.name = this.usernameModel;
    this.$emit('updateName', this.candidate);
  }

  public sendScoreCorrection (): void {
    const payload: ScoreCorrection = {
      candidateId: this.candidate.id,
      points: Math.round(this.scoreCorrection)
    };
    this.$emit('scoreCorrection', payload);
    this.scoreCorrection = 0;
  }

  @Watch('candidate')
  private handleChangedCandidateName (): void {
    this.usernameModel = this.candidate.name;
  }

  public copyJoinLink (): void {
    toClipboard(this.joinLink);
    this.$toast.info('Invitation link was copied to clipboard');
  }

  public get loopState (): LoopState | null {
    return this.besserGuesserClient.getLoop()?.state ?? null;
  }

  public get joinLink (): string {
    return `${window.location.origin}/candidate/${this.candidate.id}`;
  }
}
