






import { Component, Vue } from 'vue-property-decorator';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { OAuthApi, OAuthType } from '@/api/OAuthApi';

@Component
export default class OAuthCallback extends Vue {
  public async mounted (): Promise<void> {
    const route = this.$route;
    const searchParams = new URLSearchParams(route.hash.substring(1));

    // Iterating the search parameters
    const token = searchParams.get('access_token');
    const state = searchParams.get('state');
    const error = searchParams.get('error');
    // const errorDescription = searchParams.get('errorDescription');

    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    const actualVisitorId = result.visitorId;

    if (!state) {
      this.$toast.error('State is missing in callback');
      return;
    }

    const stateObj = decodeURIComponent(state);

    if (stateObj !== actualVisitorId) {
      this.$toast.error('State check failed');
      return;
    }

    if (error || !token) {
      this.$toast.error(error ?? 'Unknown error');
      return;
    }

    await this.getUser(token);
    this.$router.push({ name: 'home' });
  }

  private async getUser (token: string): Promise<void> {
    const r = await OAuthApi.getUser(OAuthType.TWITCH, token);
    if (r.status === 'success' && r.data) {
      this.$store.dispatch('updateUser', r.data);
      this.$toast.success('Successfully connected');
    } else {
      this.$toast.error(`Error during connection: ${r.message}`);
    }
  }
}
