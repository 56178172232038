/* eslint-disable camelcase */
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { ApiResponse } from '@/types';

export enum ApiAction {
  REQUEST_TOKEN = 'v1/oauth/requestToken.php',
  GET_USER = 'v1/oauth/getUser.php'
}

export enum OAuthType {
  TWITCH = 'twitch'
}

export type OAuthConfig = {
  clientId: string;
  authUrl: string;
  scopes: string[];
}

export type TwitchUser = {
  id: string;
  login: string;
  display_name: string;
  type: string;
  broadcaster_type: string;
  description: string;
  profile_image_url: string;
  offline_image_url: string;
  view_count: number;
  email: string;
  created_at: string;
}

export const oAuthConfigs: {[key: string]: OAuthConfig} = {
  [OAuthType.TWITCH]: {
    clientId: '71v0itxqy7rovfmmddfe3hfd71i506',
    authUrl: 'https://id.twitch.tv/oauth2/authorize',
    scopes: [
      'user:read:email'
    ]
  }
};

export class OAuthApi {
  private static async authenticate (): Promise<void> {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();

    const oAuthConfig = oAuthConfigs[OAuthType.TWITCH];
    if (!oAuthConfig) throw new Error(`Could not find oauth config for ${OAuthType.TWITCH}`);

    const query: string [] = [];
    query.push(`client_id=${oAuthConfig.clientId}`);
    query.push(`redirect_uri=${window.location.origin}/oauth/${OAuthType.TWITCH}`);
    query.push('response_type=token');
    query.push(`scope=${oAuthConfig.scopes.join(' ')}`);
    query.push(`&state=${result.visitorId}`);
    window.open(`${oAuthConfig.authUrl}?${query.join('&')}`, '_self');
  }

  public static async connect (): Promise<void> {
    this.authenticate();
  }

  public static async getUser (type: OAuthType, token: string): Promise<ApiResponse<TwitchUser>> {
    return (await axios.post(`https://backend.besserguesser.de/${ApiAction.GET_USER}`, { type, token })).data;
  }

  public static async requestToken (type: OAuthType, code: string): Promise<ApiResponse<any>> {
    return (await axios.post(`https://backend.besserguesser.de/${ApiAction.REQUEST_TOKEN}`, { type, code })).data;
  }
}
