import { ApiResponse } from '@/types';
import { BesserGuesserJSON, BesserGuesserLoopJSON, CandidateJSON, ChatAnswer, MultiQuestionAnswerLog, RoundAnswer, ScoreCorrection } from '@/types/BesserGuesser';
import axios, { AxiosRequestConfig } from 'axios';
import * as WebSocket from 'websocket';
import { BesserGuesserClient } from './BesserGuesserClient';
import { TwitchUser } from '@/api/OAuthApi';

export enum HTTPEndpoint {
  REGISTER = 'register'
}

export enum ReadyState {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED
}

export enum InMessageType {
  CONNECTION_ID = 'connectionId',
  CANDIDATES = 'candidates',
  LOOP = 'loop',
  ROUND_TIME = 'roundTime',
  ACTIVE_ANSWER = 'activeAnswer',
  ACTIVE_MULTI_ANSWER = 'activeMultiAnswer',
  CHAT_ANSWER = 'chatAnswer',
  TOGGLE_CHAT_HIGHSCORE = 'toggleChatHighscore',
  SCRAMBLE_STATE = 'scrambleState',
  PIXELATE_STATE = 'pixelateState',
  BUZZED = 'buzzed',
  PLAY_SOUND = 'playSound',
  TOGGLE_QUESTION_VISIBILITY = 'toggleQuestionVisibility'
}

export type InMessage = {
  type: InMessageType,
  data: unknown
}

export type ScrambleState = 'scramble' | 'toggle' | 'solve' | 'resume' | 'stop';
export type PixelateState = 'pixelate' | 'toggle' | 'solve' | 'resume' | 'stop';

export type UserType = 'candidate' | 'admin' | 'spectator'

export function generateHTTPBaseUrl (): string {
  if (process.env.NODE_ENV === 'production') {
    return 'https://server.besserguesser.de';
  } else {
    return 'http://localhost:1234';
  }
}

export function generateWSBaseUrl (): string {
  if (process.env.NODE_ENV === 'production') {
    return 'wss://server.besserguesser.de';
  } else {
    return 'ws://localhost:1234';
  }
}

export class WebsocketClient {
  private connection: WebSocket.w3cwebsocket | null = null;
  private connectionId: string | null = null;
  private readyState = ReadyState.CLOSED;
  private besserGuesserClient: BesserGuesserClient;

  public constructor () {
    this.besserGuesserClient = new BesserGuesserClient();
  }

  public getBesserGuesserClient (): BesserGuesserClient {
    return this.besserGuesserClient;
  }

  public isConnected (): boolean {
    return this.readyState === ReadyState.OPEN;
  }

  public getConnectionId (): string | null {
    return this.connectionId;
  }

  public getReadyState (): ReadyState {
    return this.readyState;
  }

  public getConnectionStatusString (): string {
    switch (this.readyState) {
      case ReadyState.CLOSED: return 'closed';
      case ReadyState.CLOSING: return 'closing...';
      case ReadyState.CONNECTING: return 'connecting';
      case ReadyState.OPEN: return 'connected';
      default: return 'unknown';
    }
  }

  public toggleChatHighscore (): void {
    axios.post(`${generateHTTPBaseUrl()}/toggleChatHighscore`, undefined, this.generateHeaders());
  }

  public async evaluateMultiGuess (candidateId: string, correct: boolean): Promise<ApiResponse<MultiQuestionAnswerLog>> {
    const r = (await axios.post<ApiResponse<MultiQuestionAnswerLog>>(`${generateHTTPBaseUrl()}/evaluateMultiGuess`, { candidateId, correct }, this.generateHeaders())).data;
    if (r.status === 'success' && r.data) {
      this.besserGuesserClient.updateActiveMultiAnswer(r.data);
    }
    return r;
  }

  public async register (userType: UserType, user?: TwitchUser): Promise<ApiResponse<BesserGuesserJSON>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/register`, { userType, user }, this.generateHeaders())).data;
  }

  public async addCandidate (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/addCandidate`, undefined, this.generateHeaders())).data;
  }

  public async removeCandidate (id: string): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/removeCandidate`, { playerId: id }, this.generateHeaders())).data;
  }

  public async updateCandidateName (id: string, name: string): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/updateCandidateName`, { playerId: id, name }, this.generateHeaders())).data;
  }

  public async sendScoreCorrection (payload: ScoreCorrection): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/sendScoreCorrection`, payload, this.generateHeaders())).data;
  }

  public async selectQuestion (questionIndex: [number, number]): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/selectQuestion`, { questionIndex }, this.generateHeaders())).data;
  }

  public async startLoop (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/startLoop`, undefined, this.generateHeaders())).data;
  }

  public async endLoop (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/endLoop`, undefined, this.generateHeaders())).data;
  }

  public async restartLoop (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/restartLoop`, undefined, this.generateHeaders())).data;
  }

  public async setScramblerState (state: ScrambleState): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/setScramblerState`, { state }, this.generateHeaders())).data;
  }

  public async setPixelateState (state: PixelateState): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/setPixelateState`, { state }, this.generateHeaders())).data;
  }

  public async setBuzzResult (state: boolean): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/setBuzzResult`, { state }, this.generateHeaders())).data;
  }

  public async resetBuzzer (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/resetBuzzer`, undefined, this.generateHeaders())).data;
  }

  public async forceEndBuzzRound (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/forceEndBuzzRound`, undefined, this.generateHeaders())).data;
  }

  public async toggleQuestionVisibility (): Promise<ApiResponse<boolean>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/toggleQuestionVisibility`, undefined, this.generateHeaders())).data;
  }

  public async presentAnswers (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/presentAnswers`, undefined, this.generateHeaders())).data;
  }

  public async presentResults (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/presentResults`, undefined, this.generateHeaders())).data;
  }

  public async startNextRound (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/startNextRound`, undefined, this.generateHeaders())).data;
  }

  public async startNextChildRound (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/startNextChildRound`, undefined, this.generateHeaders())).data;
  }

  public async prepareNextRound (): Promise<ApiResponse<any>> {
    return (await axios.post(`${generateHTTPBaseUrl()}/prepareNextRound`, undefined, this.generateHeaders())).data;
  }

  public async useJoker (): Promise<ApiResponse<boolean>> {
    const r = (await axios.post<ApiResponse<boolean>>(`${generateHTTPBaseUrl()}/useJoker`, undefined, this.generateHeaders())).data;
    return r;
  }

  public async submitGuess (guess: number): Promise<ApiResponse<RoundAnswer>> {
    const r = (await axios.post<ApiResponse<RoundAnswer>>(`${generateHTTPBaseUrl()}/submitGuess`, { guess }, this.generateHeaders())).data;
    if (r.status === 'success' && r.data) {
      this.besserGuesserClient.updateActiveAnswer(r.data);
    }
    return r;
  }

  public async submitMultiGuess (guess: string): Promise<ApiResponse<MultiQuestionAnswerLog>> {
    const r = (await axios.post<ApiResponse<MultiQuestionAnswerLog>>(`${generateHTTPBaseUrl()}/submitMultiGuess`, { guess }, this.generateHeaders())).data;
    if (r.status === 'success' && r.data) {
      this.besserGuesserClient.updateActiveMultiAnswer(r.data);
    }
    return r;
  }

  public async hitBuzzer (): Promise<boolean> {
    const r = (await axios.post<ApiResponse<boolean>>(`${generateHTTPBaseUrl()}/hitBuzzer`, undefined, this.generateHeaders())).data;
    if (r.status === 'success' && r.data !== undefined) return r.data;
    return false;
  }

  private generateHeaders (): AxiosRequestConfig {
    return {
      headers: { 'Connection-Id': this.connectionId ?? '' }
    };
  }

  private handleIncomingWebsocketMessage (message: InMessage) {
    switch (message.type) {
      case InMessageType.CONNECTION_ID: this.connectionId = message.data as string; break;
      case InMessageType.CANDIDATES: this.besserGuesserClient.updateCandidates(message.data as CandidateJSON[]); break;
      case InMessageType.LOOP: this.besserGuesserClient.updateLoop(message.data as BesserGuesserLoopJSON); break;
      case InMessageType.ROUND_TIME: this.besserGuesserClient.updateRoundTime(message.data as number); break;
      case InMessageType.ACTIVE_ANSWER: this.besserGuesserClient.updateActiveAnswer(message.data as RoundAnswer); break;
      case InMessageType.ACTIVE_MULTI_ANSWER: this.besserGuesserClient.updateActiveMultiAnswer(message.data as MultiQuestionAnswerLog); break;
      case InMessageType.CHAT_ANSWER: this.besserGuesserClient.updateChatAnswer(message.data as ChatAnswer); break;
      case InMessageType.TOGGLE_CHAT_HIGHSCORE: this.besserGuesserClient.trigger('toggleChatHighscore', message.data); break;
      case InMessageType.SCRAMBLE_STATE: this.besserGuesserClient.trigger('scrambleStateChange', message.data as ScrambleState); break;
      case InMessageType.PIXELATE_STATE: this.besserGuesserClient.trigger('pixelateStateChange', message.data as PixelateState); break;
      case InMessageType.BUZZED: this.besserGuesserClient.trigger('buzzed', message.data as string); break;
      case InMessageType.PLAY_SOUND: this.besserGuesserClient.trigger('playSound', message.data as string); break;
      case InMessageType.TOGGLE_QUESTION_VISIBILITY: this.besserGuesserClient.toggleQuestionVisibility(message.data as boolean); break;
    }
  }

  public disconnect (): void {
    if (!this.connection) return;
    this.connection.close(1000, 'clientdisconnect');
  }

  public async connect (): Promise<boolean> {
    if (this.connection && this.connection.readyState !== ReadyState.CLOSED) {
      return false;
    }

    const connection = new WebSocket.w3cwebsocket(`${generateWSBaseUrl()}`, 'besserguesser-protocol', undefined, undefined); // eslint-disable-line new-cap
    this.connection = connection;
    this.readyState = connection.readyState;

    return new Promise((resolve, reject) => {
      connection.onerror = (error) => {
        this.readyState = connection.readyState;
        reject(error);
      };

      connection.onopen = () => {
        this.readyState = connection.readyState;
        resolve(true);
      };

      connection.onclose = (_event) => {
        this.connectionId = null;
        this.readyState = connection.readyState;
      };

      connection.onmessage = (message) => {
        try {
          if (typeof message.data !== 'string') throw new Error(`illegal websocket data type: ${typeof message.data}`);
          const data = JSON.parse(message.data);
          this.handleIncomingWebsocketMessage(data);
        } catch (e) {
          console.error(e);
        }
      };
    });
  }
}
