import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AdminView from '../views/AdminView.vue';
// import EmptyView from '../views/EmptyView.vue';
import CandidateView from '../views/CandidateViewV2.vue';
import StreamOverlayView from '../views/StreamOverlayViewV2.vue';
import OAuthCallback from '../views/OAuthCallback.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/candidate/:t?',
    name: 'candidate',
    props: true,
    component: CandidateView
  },
  {
    path: '/stream-overlay',
    name: 'stream-overlay',
    props: true,
    component: StreamOverlayView
  },
  {
    path: '/oauth/twitch',
    name: 'twitch-oauth-callback',
    component: OAuthCallback
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
