











import { toChatTotalScores } from '@/functions';
import { ChatTotalScore, RoundResult } from '@/types/BesserGuesser';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CandidateList extends Vue {
  @Prop({ type: Array, required: true }) private allRoundResults!: RoundResult[];

  private get currentTotalScores (): ChatTotalScore[] {
    return toChatTotalScores(this.allRoundResults);
  }
}
