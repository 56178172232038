import { BesserGuesserJSON, BesserGuesserLoopJSON, CandidateJSON, ChatAnswer, MultiQuestionAnswerLog, QuestionMatrix, RoundAnswer } from '@/types/BesserGuesser';

export type BesserGuesserEvents = 'toggleChatHighscore' | 'scrambleStateChange' | 'pixelateStateChange' | 'buzzed' | 'playSound' | 'multiAnswerUpdate';

export type BesserGuesserEventListener = {
  type: BesserGuesserEvents;
  callback: (event: any) => void
}

export class BesserGuesserClient {
  private token = '';
  private json: BesserGuesserJSON | null;
  private chatAnswers: ChatAnswer[] = [];
  private eventListeners: BesserGuesserEventListener[] = [];

  public constructor () {
    this.json = null;
  }

  public on (type: BesserGuesserEvents, callback: (event: any) => void): BesserGuesserEventListener {
    const listener: BesserGuesserEventListener = { type, callback };
    this.eventListeners.push(listener);
    return listener;
  }

  public trigger (type: BesserGuesserEvents, event: any): void {
    const listeners = this.eventListeners.filter(l => l.type === type);
    listeners.forEach(l => l.callback(event));
  }

  public removeListener (listener: BesserGuesserEventListener): void {
    const index = this.eventListeners.findIndex(l => l === listener);
    if (index > -1) {
      this.eventListeners.splice(index, 1);
    }
  }

  public setToken (t: string): void {
    this.token = t;
  }

  // public getToken (): string {
  //   return this.token;
  // }

  public getJSON (): BesserGuesserJSON | null {
    return this.json;
  }

  public getLoop (): BesserGuesserLoopJSON | null {
    return this.json?.loop ?? null;
  }

  public getQuestionMatrix (): QuestionMatrix | null {
    return this.getLoop()?.questions ?? null;
  }

  public getChatAnswers (): ChatAnswer[] {
    return this.chatAnswers;
  }

  public clearChatAnswers (): void {
    this.chatAnswers = [];
  }

  public pushChatAnswer (chatAnswer: ChatAnswer): void {
    this.chatAnswers.push(chatAnswer);
  }

  public getUsedQuestionIndices (): [number, number][] {
    const loop = this.getLoop();
    if (!loop) return [];
    return loop.roundResults.map(r => r.questionIndex);
  }

  public getCandidates (): CandidateJSON[] {
    return this.json ? this.json.candidates : [];
  }

  public updateCandidates (candidates: CandidateJSON[]): void {
    if (!this.json) {
      console.log('Ignoring updateCandidates call before besser guesser client is still null');
      return;
    }

    this.json.candidates = candidates;
  }

  public updateLoop (loop: BesserGuesserLoopJSON): void {
    if (!this.json) {
      console.log('Ignoring updateLoop call before besser guesser client is still null');
      return;
    }
    this.json.loop = loop;
  }

  public toggleQuestionVisibility (state: boolean): void {
    if (!this.json || !this.json.loop) return;
    this.json.loop.questionVisible = state;
  }

  public updateRoundTime (time: number): void {
    if (!this.json) {
      console.log('Ignoring updateRoundTime call before besser guesser client is still null');
      return;
    }

    this.json.loop.roundTime = time;
  }

  public updateActiveAnswer (roundAnswer: RoundAnswer): void {
    if (!this.json?.loop) {
      console.log('Ignoring updateActiveAnswer call before besser guesser client is still null');
      return;
    }

    const index = this.json.loop.activeAnswers.findIndex(a => a.candidateId === roundAnswer.candidateId);
    if (index === -1) {
      this.json.loop.activeAnswers.push(roundAnswer);
    } else {
      this.json.loop.activeAnswers.splice(index, 1, roundAnswer);
    }
  }

  public updateActiveMultiAnswer (roundMultiAnswer: MultiQuestionAnswerLog): void {
    if (!this.json?.loop) {
      return;
    }

    const index = this.json.loop.multiAnswers.findIndex(a => a.candidateId === roundMultiAnswer.candidateId && a.childQuestionIndex === roundMultiAnswer.childQuestionIndex);
    if (index === -1) {
      this.json.loop.multiAnswers.push(roundMultiAnswer);
    } else {
      this.json.loop.multiAnswers.splice(index, 1, roundMultiAnswer);
    }

    this.trigger('multiAnswerUpdate', roundMultiAnswer);
  }

  public updateChatAnswer (chatAnswer: ChatAnswer): void {
    if (!this.json?.loop) {
      console.log('Ignoring updateChatAnswer call before besser guesser client is still null');
      return;
    }
    this.chatAnswers.push(chatAnswer);
  }

  public updateJSON (json: BesserGuesserJSON): void {
    this.json = json;
  }
}
