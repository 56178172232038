


























































import { BesserGuesserClient } from '@/models/BesserGuesserClient';
import { PixelateState, ScrambleState, WebsocketClient } from '@/models/WebsocketClient';
import { Vue, Component } from 'vue-property-decorator';
import CandidateFormItem from '@/components/CandidateForm/CandidateFormItem.vue';
import { BesserGuesserLoopJSON, CandidateJSON, LoopState, Question, ScoreCorrection } from '@/types/BesserGuesser';

@Component({
  components: {
    CandidateFormItem
  }
})
export default class ConnectionComponent extends Vue {
  private websocketClient = this.$store.getters.websocketClient as WebsocketClient;
  private besserGuesserClient = this.$store.getters.besserGuesserClient as BesserGuesserClient;

  public addCandidate (): void {
    this.websocketClient.addCandidate();
  }

  public startLoop (): void {
    this.websocketClient.startLoop();
  }

  public endLoop (): void {
    this.websocketClient.endLoop();
  }

  public restartLoop (): void {
    this.websocketClient.restartLoop();
  }

  public setScramblerState (state: ScrambleState): void {
    this.websocketClient.setScramblerState(state);
  }

  public setPixelateState (state: PixelateState): void {
    this.websocketClient.setPixelateState(state);
  }

  public setBuzzResult (state: boolean): void {
    this.websocketClient.setBuzzResult(state);
  }

  public resetBuzzer (): void {
    this.websocketClient.resetBuzzer();
  }

  public forceEndBuzzRound (): void {
    this.websocketClient.forceEndBuzzRound();
  }

  public async toggleQuestionVisibility (): Promise<void> {
    if (this.besserGuesserLoopJSON) {
      const state = await this.websocketClient.toggleQuestionVisibility();
      if (state.status === 'success') {
        this.besserGuesserLoopJSON.questionVisible = state.data ?? false;
      }
    }
  }

  public presentAnswers (): void {
    this.websocketClient.presentAnswers();
  }

  public startNextChildRound (): void {
    this.websocketClient.startNextChildRound();
  }

  public presentResults (): void {
    this.websocketClient.presentResults();
  }

  public startNextRound (): void {
    this.websocketClient.startNextRound();
  }

  public removeCandidate (candidate: CandidateJSON) {
    this.websocketClient.removeCandidate(candidate.id);
  }

  public updateCandidateName (candidate: CandidateJSON): void {
    this.websocketClient.updateCandidateName(candidate.id, candidate.name);
  }

  public handleScoreCorrection (payload: ScoreCorrection): void {
    this.websocketClient.sendScoreCorrection(payload);
  }

  public get besserGuesserLoopJSON (): BesserGuesserLoopJSON | null {
    return this.besserGuesserClient.getLoop();
  }

  public get currentChildIndex (): number {
    return this.besserGuesserLoopJSON?.currentChildIndex ?? -1;
  }

  public get totalChildrenLength (): number {
    return this.besserGuesserLoopJSON?.totalChildrenLength ?? -1;
  }

  public get candidates (): CandidateJSON[] {
    return this.besserGuesserClient.getCandidates();
  }

  public get loopState (): LoopState | null {
    return this.besserGuesserLoopJSON?.state ?? null;
  }

  public get someoneBuzzed (): boolean {
    return !!this.besserGuesserLoopJSON?.buzzedCandidateId;
  }

  public get buzzedCandidate (): CandidateJSON | null {
    return this.candidates.find(c => c.id === this.besserGuesserLoopJSON?.buzzedCandidateId) ?? null;
  }

  public get activeQuestion (): Question | null {
    const activeQuestionIndex = this.besserGuesserLoopJSON?.activeQuestionIndex;
    const questionMatrix = this.besserGuesserClient?.getQuestionMatrix();
    if (!activeQuestionIndex || !questionMatrix) return null;
    return questionMatrix[activeQuestionIndex[0]].questions[activeQuestionIndex[1]] ?? null;
  }
}
