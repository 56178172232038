import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toast, { POSITION } from 'vue-toastification';
import vuetify from './plugins/vuetify';
import 'vue-toastification/dist/index.css';

Vue.config.productionTip = false;
Vue.use(Toast, {
  position: POSITION.BOTTOM_CENTER
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
