import { TwitchUser } from '@/api/OAuthApi';
import { BesserGuesserClient } from '@/models/BesserGuesserClient';
import { WebsocketClient } from '@/models/WebsocketClient';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

type RootState = {
  websocketClient: WebsocketClient;
  user: TwitchUser | null;
}

export default new Vuex.Store<RootState>({
  state: {
    websocketClient: new WebsocketClient(),
    user: null
  },
  getters: {
    websocketClient: (state): WebsocketClient => {
      return state.websocketClient;
    },
    besserGuesserClient: (state): BesserGuesserClient => {
      return state.websocketClient.getBesserGuesserClient();
    },
    user: (state): TwitchUser | null => {
      return state.user;
    }
  },
  actions: {
    updateUser ({ commit }, user: TwitchUser): void {
      commit('UPDATE_USER', user);
    }
  },
  mutations: {
    UPDATE_USER (state, user: TwitchUser): void {
      state.user = user;
    }
  },
  modules: {
  }
});
